import gql from "graphql-tag";

export const referralsData = gql`
  query referralsData($orderBy: OrderBy, $filters: Filter) {
    referrals(orderBy: $orderBy, filters: $filters) {
      data {
        id
        name
      }
    }
  }
`;
