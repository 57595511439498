<template>
  <div>
    <KTCard>
      <TransactionForm
        ref="transactionForm"
        :referrals="referrals"
        :transaction="transaction"
        @searchChange="onSearchChange"
        @submitForm="onSubmitForm"
      />

      <template v-slot:footer>
        <div class="d-flex justify-content-between">
          <div>
            <ButtonElement
              data-cy="submit-btn"
              variant="primary"
              text="save"
              class="mr-3"
              @click="onClickSubmit"
            />

            <ButtonElement
              data-cy="back-btn"
              variant="text-primary"
              text="back"
              @click="onClickBack"
            />
          </div>

          <ButtonElement
            v-b-modal.confirm-modal
            data-cy="delete-btn"
            variant="text-danger"
            text="delete"
          />
        </div>
      </template>
    </KTCard>

    <ConfirmModal
      :modal-title="$t('page.transactionEdit.modalTitle')"
      is-centered
      @actionConfirmed="onConfirmDeleteTransaction"
    >
      <template v-slot:body>
        {{ $t("page.transactionEdit.modalDescription") }}
      </template>
    </ConfirmModal>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { referralsData, transactionData } from "./gql/queries";
import { updateTransaction, deleteTransaction } from "./gql/mutations";
import apolloMixin from "@/mixins/apollo.mixin";

import KTCard from "@/components/card/KTCard";
import TransactionForm from "../_components/TransactionForm";
import ButtonElement from "@/components/ButtonElement";
import ConfirmModal from "@/components/modal/ConfirmModal";

const CONTENT_WIDTH = 700;

export default {
  name: "EmployeeEditTransaction",
  components: {
    KTCard,
    TransactionForm,
    ButtonElement,
    ConfirmModal,
  },

  mixins: [apolloMixin],

  data: () => ({
    sortingOptions: {
      column: "createdAt",
      direction: "desc",
    },
    referrals: [],
    transaction: {},
    query: "",
  }),

  computed: {
    locale() {
      return this.$i18n.locale;
    },

    transactionId() {
      return this.$route.params.id;
    },
  },

  watch: {
    query: {
      handler: "getReferrals",
      deep: true,
    },
  },

  async created() {
    await this.getReferrals();
    await this.getTransaction();
  },

  mounted() {
    this.$setBreadcrumbs(["EmployeePayments"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapActions("publicReferral", ["createReferral"]),

    async getReferrals() {
      const filters = {};

      if (this.query) {
        filters.where = {
          column: "name",
          operator: "like",
          value: this.query,
        };
      }

      const { referrals } = await this.$get(referralsData, {
        filters,
        sortingOptions: this.sortingOptions,
      });

      this.referrals = referrals.data;
    },

    async getTransaction() {
      const { transaction } = await this.$get(transactionData, { id: this.transactionId });

      this.transaction = transaction;
    },

    onSearchChange(query) {
      this.query = query;
    },

    onClickSubmit() {
      this.$refs.transactionForm.submitForm();
    },

    async onSubmitForm(transactionData) {
      await this.$post(updateTransaction, transactionData);
      await this.$router.push({ name: "EmployeePayments" });
    },

    onClickBack() {
      this.$router.push({ name: "EmployeePayments" });
    },

    async onConfirmDeleteTransaction() {
      await this.$post(deleteTransaction, { id: this.transaction.id });
      await this.$router.push({ name: "EmployeePayments" });
    },
  },
};
</script>
