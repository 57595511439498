import gql from "graphql-tag";

export const updateTransaction = gql`
  mutation updateTransaction(
    $id: ID!
    $type: String!
    $amount: Float!
    $referral: Associate
    $comment: String
    $userId: Int
  ) {
    updateTransaction(
      id: $id
      type: $type
      amount: $amount
      referral: $referral
      comment: $comment
      userId: $userId
    ) {
      id
      type
      amount
      referral {
        id
        name
      }
      userId
      comment
    }
  }
`;

export const deleteTransaction = gql`
  mutation deleteTransaction($id: ID!) {
    deleteTransaction(id: $id) {
      id
    }
  }
`;
