<template>
  <div data-cy="employee-payments-page">
    <div class="d-flex justify-content-between mb-4">
      <ButtonElement
        data-cy="filters-btn"
        no-focus
        :variant="filtersBtnVariant()"
        text="filters"
        @click="onClickFilters"
      >
        <span>{{ $t("button.filters") }}</span>
        <span>{{ filtersCounter() }}</span>
      </ButtonElement>

      <div>
        <ButtonElement
          v-if="currentTable === 'transactions'"
          data-cy="add-btn"
          class="mr-5"
          variant="primary"
          text="add"
          @click="onClickAdd"
        >
          <span>{{ $t("button.add") }}</span>
        </ButtonElement>

        <b-form-radio-group v-model="currentTable" buttons button-variant="outline-primary">
          <b-form-radio data-cy="transactions-radio" value="transactions">
            {{ $t("label.operation.list") }}
          </b-form-radio>

          <b-form-radio data-cy="withdrawals-radio" value="withdrawals">
            {{ $t("label.withdrawal.list") }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>

    <EmployeeWithdrawalList
      v-show="isOpenedWithdrawalsTable"
      ref="withdrawals"
      :pagination-data="withdrawalsPaginationData"
      :withdrawals="withdrawals"
      :applied-filters="appliedWithdrawalsFilters"
      @changeWithdrawalStatus="getWithdrawals"
      @pageChange="getWithdrawals"
      @applyFilters="onApplyFilters"
      @resetFilters="resetFilters"
    />

    <EmployeeTransactionList
      v-show="!isOpenedWithdrawalsTable"
      ref="transactions"
      :pagination-data="transactionsPaginationData"
      :transactions="transactions"
      :applied-filters="appliedTransactionsFilters"
      @pageChange="getTransactions"
      @applyFilters="onApplyFilters"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { withdrawalsData, transactionsData } from "./gql/queries";
import { TYPE_VALUES } from "@/api/payments";
import { serializeFilters } from "@/services/filterBuilder.service";

import EmployeeWithdrawalList from "./_components/EmployeeWithdrawalList";
import EmployeeTransactionList from "./_components/EmployeeTransactionList";
import ButtonElement from "@/components/ButtonElement";

const { withdrawals, transactions } = TYPE_VALUES;

const CONTENT_WIDTH = 900;

export default {
  name: "EmployeePayments",
  components: {
    EmployeeTransactionList,
    EmployeeWithdrawalList,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    currentTable: transactions,
    sortingOptions: {
      column: "createdAt",
      direction: "desc",
    },
    withdrawalsPaginationData: {},
    withdrawals: [],
    transactionsPaginationData: {},
    transactions: [],
    fieldsWithdrawals: [],
    fieldsTransactions: [],
    appliedWithdrawalsFilters: {},
    appliedTransactionsFilters: {},
  }),

  computed: {
    ...mapState("employee", ["paymentsFilters"]),

    isOpenedWithdrawalsTable() {
      return this.currentTable === withdrawals;
    },
  },

  watch: {
    fieldsWithdrawals: "onChangeFieldsWithdrawals",
    fieldsTransactions: "onChangeFieldsTransactions",
  },

  async created() {
    if (this.paymentsFilters.withdrawals) {
      this.appliedWithdrawalsFilters = this.paymentsFilters.withdrawals;
    }

    if (this.paymentsFilters.transactions) {
      this.appliedTransactionsFilters = this.paymentsFilters.transactions;
    }
  },

  mounted() {
    this.fieldsWithdrawals = this.$refs.withdrawals.fields;
    this.fieldsTransactions = this.$refs.transactions.fields;
    this.$setBreadcrumbs();
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapMutations("employee", ["SET_PAYMENTS_FILTERS"]),

    onChangeFieldsWithdrawals() {
      this.getWithdrawals();
    },

    onChangeFieldsTransactions() {
      this.getTransactions();
    },

    async getWithdrawals(page) {
      let filtersOptions = [];
      const isAppliedFilters = !!Object.keys(this.appliedWithdrawalsFilters).length;

      if (page && page === this.withdrawalsPaginationData.currentPage) return;

      if (isAppliedFilters) {
        filtersOptions = serializeFilters(this.fieldsWithdrawals, this.appliedWithdrawalsFilters);
      }

      const { withdrawals } = await this.$get(withdrawalsData, {
        filtersOptions,
        sortingOptions: this.sortingOptions,
        page,
      });

      this.withdrawalsPaginationData = withdrawals.paginatorInfo;
      this.withdrawals = withdrawals.data;
    },

    async getTransactions(page) {
      let filtersOptions = [];

      const isAppliedFilters = !!Object.keys(this.appliedTransactionsFilters).length;

      if (page && page === this.transactionsPaginationData.currentPage) return;

      if (isAppliedFilters) {
        filtersOptions = serializeFilters(this.fieldsTransactions, this.appliedTransactionsFilters);
      }

      const { transactions } = await this.$get(transactionsData, {
        filtersOptions,
        sortingOptions: this.sortingOptions,
        page,
      });

      this.transactionsPaginationData = transactions.paginatorInfo;
      this.transactions = transactions.data;
    },

    async getCurrentTableData() {
      this.currentTable === withdrawals
        ? await this.getWithdrawals()
        : await this.getTransactions();
    },

    async onApplyFilters({ type, fields, filters }) {
      if (type === withdrawals) {
        this.fieldsWithdrawals = fields;
        this.appliedWithdrawalsFilters = filters;
      } else {
        this.fieldsTransactions = fields;
        this.appliedTransactionsFilters = filters;
      }

      this.SET_PAYMENTS_FILTERS({ type, filters });
      await this.getCurrentTableData();
    },

    async resetFilters(type) {
      if (type === withdrawals) {
        this.fieldsWithdrawals = [];
        this.appliedWithdrawalsFilters = {};
      } else {
        this.fieldsTransactions = [];
        this.appliedTransactionsFilters = {};
      }

      const filters = null;

      this.SET_PAYMENTS_FILTERS({ type, filters });
      await this.getCurrentTableData();
    },

    onClickFilters() {
      this.$refs[this.currentTable].showFiltersModal();
    },

    onClickAdd() {
      this.$router.push({ name: "EmployeeAddTransaction" });
    },

    filtersBtnVariant() {
      const isAppliedFilters =
        this.currentTable === withdrawals
          ? !!Object.keys(this.appliedWithdrawalsFilters).length
          : !!Object.keys(this.appliedTransactionsFilters).length;

      return isAppliedFilters ? "dark" : "outline-dark";
    },

    filtersCounter() {
      const counter =
        this.currentTable === withdrawals
          ? Object.keys(this.appliedWithdrawalsFilters).length
          : Object.keys(this.appliedTransactionsFilters).length;

      return counter ? ` · ${counter}` : "";
    },
  },
};
</script>
