<template>
  <div>
    <KTCard card-class="card-table">
      <OurTable
        data-cy="employee-transactions-table"
        table-classes="our-table table-responsive-xl"
        :pagination-data="paginationData"
        :items="allTransactions"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="onPageChange"
      >
        <template v-slot:cell-actions="{ scopeProps }">
          <SvgIcon
            v-if="scopeProps.item.actions"
            data-cy="edit-transaction-btn"
            icon-folder="Design"
            icon-name="Edit"
            icon-color="primary"
            icon-size="md"
            @click="onClickEdit(scopeProps.item)"
          />
        </template>
      </OurTable>
    </KTCard>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { TYPE_VALUES } from "@/api/payments";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import SvgIcon from "@/components/svg/SvgIcon";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";
import ButtonElement from "@/components/ButtonElement";

const { transactions } = TYPE_VALUES;

export default {
  name: "EmployeeTransactionList",
  components: {
    KTCard,
    OurTable,
    SvgIcon,
    PageModal,
    OurFilters,
    ButtonElement,
  },

  props: {
    paginationData: {
      type: Object,
      default: () => ({}),
    },

    transactions: {
      type: Array,
      default: () => [],
      required: true,
    },

    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "amount",
          label: this.$t("label.amount"),
          type: "amount",
          thClass: "text-right",
          tdAttr: {
            style: "width: 15%; max-width: 90px; text-align: right;",
          },
          filter: {
            component: "input",
            type: "number",
            operator: "like",
            classes: "w-50",
          },
        },
        {
          key: "type",
          label: this.$t("label.type"),
          tdAttr: {
            style: "width: 20%; max-width: 100px;",
          },
          filter: {
            component: "select",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "order",
          label: this.$t("label.order._"),
          type: "link",
          tdAttr: {
            style: "width: 25%; max-width: 110px;",
          },
          filter: {
            component: "input",
            type: "text",
            column: "referral.name",
            operator: "like",
          },
        },
        {
          key: "agent",
          label: this.$t("label.agent._"),
          type: "link",
          tdAttr: {
            style: "width: 30%; max-width: 120px;",
          },
          filter: {
            component: "select-agent",
            column: "referral.user.id",
            operator: "=",
          },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 80px;",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("transaction", ["types"]),

    allTransactions() {
      return this.transactions.map((transaction) => {
        const { id, amount, type, createdAt, referral, withdrawal, user } = transaction;

        const transactionAmount = {
          amount,
          type,
        };

        const actions = Boolean(type !== "withdrawal");

        const agent = {
          route: {
            name: "AgentEdit",
          },
        };

        if (referral) {

          if (user) {
            agent.title = `${user.firstName} ${user.lastName}`;
            agent.route.params = { id: user.id };
          } else {
            agent.title = `${referral.user.firstName} ${referral.user.lastName}`;
            agent.route.params = { id: referral.user.id };
          }
          
        } else if (withdrawal) {
          agent.title = `${withdrawal.user.firstName} ${withdrawal.user.lastName}`;
          agent.route.params = { id: withdrawal.user.id };
        }

        const transactionType = this.types.find((type) => type.code === transaction.type)?.label;
        let order = "–";

        if (transaction.referral) {
          order = {
            title: transaction.referral.name,
            route: {
              name: "ReferralEdit",
              params: { id: transaction.referral.id },
            },
          };
        }

        return {
          id,
          dateCreate: createdAt,
          amount: transactionAmount,
          actions,
          agent,
          type: transactionType,
          order,
        };
      });
    },

    fieldsForFilters() {
      return this.fields.map((field) => {
        if (field.key === "type") {
          field.filter.selectOptions = this.types;
          field.filter.trackBy = "code";
        }

        return field;
      });
    },
  },

  methods: {
    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    showFiltersModal() {
      this.$refs.filtersModal.showModal();
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    onApplyFilters(filters) {
      this.$emit("applyFilters", {
        type: transactions,
        fields: this.fields,
        filters,
      });
    },

    onResetFilters() {
      this.$emit("resetFilters", transactions);

      this.$refs.filtersModal.closeModal();
    },

    onClickEdit({ id }) {
      this.$router.push({ name: "EmployeeEditTransaction", params: { id } });
    },
  },
};
</script>
