<template>
  <KTCard>
    <TransactionForm
      ref="transactionForm"
      :referrals="referrals"
      :referral-id="referralId"
      @searchChange="onSearchChange"
      @submitForm="onSubmitForm"
    />

    <template v-slot:footer>
      <div class="d-flex justify-content-start">
        <ButtonElement
          data-cy="submit-btn"
          variant="primary"
          text="save"
          class="mr-3"
          @click="onClickSubmit"
        />

        <ButtonElement data-cy="back-btn" variant="text-primary" text="back" @click="onClickBack" />
      </div>
    </template>
  </KTCard>
</template>

<script>
import { mapActions } from "vuex";
import { referralsData } from "./gql/queries";
import { createTransaction } from "./gql/mutations";
import apolloMixin from "@/mixins/apollo.mixin";

import KTCard from "@/components/card/KTCard";
import TransactionForm from "../_components/TransactionForm";
import ButtonElement from "@/components/ButtonElement";

const CONTENT_WIDTH = 700;

export default {
  name: "EmployeeAddTransaction",
  components: {
    KTCard,
    TransactionForm,
    ButtonElement,
  },

  mixins: [apolloMixin],

  data: () => ({
    sortingOptions: {
      column: "createdAt",
      direction: "desc",
    },
    referrals: [],
    query: "",
  }),

  computed: {
    locale() {
      return this.$i18n.locale;
    },

    referralId() {
      return this.$route.params.id;
    },
  },

  watch: {
    query: {
      handler: "getReferrals",
      deep: true,
    },
  },

  async created() {
    await this.getReferrals();
  },

  mounted() {
    this.$setBreadcrumbs(["EmployeePayments"]);
    this.$setMaxContentWidth(CONTENT_WIDTH);
  },

  beforeDestroy() {
    this.$resetMaxContentWidth();
  },

  methods: {
    ...mapActions("publicReferral", ["createReferral"]),

    async getReferrals() {
      const filters = {};

      if (this.referralId) {
        filters.where = {
          column: "id",
          operator: this.referralId,
          value: this.referralId,
        };
      }

      if (this.query) {
        filters.where = {
          column: "name",
          operator: "like",
          value: this.query,
        };
      }

      const { referrals } = await this.$get(referralsData, {
        filters,
        sortingOptions: this.sortingOptions,
      });

      this.referrals = referrals.data;
    },

    onSearchChange(query) {
      this.query = query;
    },

    onClickSubmit() {
      this.$refs.transactionForm.submitForm();
    },

    async onSubmitForm(transactionData) {
      await this.$post(createTransaction, transactionData);
      await this.$router.push({ name: "EmployeePayments" });
    },

    onClickBack() {
      this.$router.push({ name: "EmployeePayments" });
    },
  },
};
</script>
