import gql from "graphql-tag";

export const referralsData = gql`
  query referralsData($orderBy: OrderBy, $filters: Filter) {
    referrals(orderBy: $orderBy, filters: $filters) {
      data {
        id
        name
      }
    }
  }
`;

export const transactionData = gql`
  query transactionData($id: ID!) {
    transaction(id: $id) {
      id
      type
      amount
      referral {
        id
        name
        user {
          id
          firstName
          lastName
        }
      }
      user {
        id
        firstName
        lastName
      }
      comment
    }
  }
`;
