import gql from "graphql-tag";

export const createTransaction = gql`
  mutation createTransaction(
    $type: String!
    $amount: Float!
    $referral: Associate
    $comment: String
    $userId: Int
  ) {
    createTransaction(type: $type, amount: $amount, referral: $referral, comment: $comment, userId: $userId) {
      id
      type
      amount
      referral {
        id
        name
      }
      userId
      comment
    }
  }
`;
