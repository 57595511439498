<template>
  <div>
    <KTCard card-class="card-table">
      <OurTable
        data-cy="employee-withdrawals-table"
        table-classes="our-table table-responsive-xl"
        :pagination-data="paginationData"
        :items="allWithdrawals"
        :fields="fields"
        :filters="appliedFilters"
        @pageChange="onPageChange"
      >
        <template v-slot:cell-actions="{ scopeProps }">
          <template v-if="isStatusNew(scopeProps.item)">
            <SvgIcon
              v-b-modal.approveModal
              data-cy="approve-withdrawal-btn"
              icon-folder="Navigation"
              icon-name="Check"
              icon-color="success"
              icon-size="md"
              class="mr-3"
              @click="setCurrentWithdrawalId(scopeProps.item)"
            />

            <SvgIcon
              v-b-modal.rejectModal
              data-cy="reject-withdrawal-btn"
              icon-folder="Navigation"
              icon-name="Close"
              icon-color="danger"
              icon-size="md"
              @click="setCurrentWithdrawalId(scopeProps.item)"
            />
          </template>
        </template>
      </OurTable>
    </KTCard>

    <ConfirmModal
      modal-id="approveModal"
      :modal-title="$t('page.payments.approveModalTitle')"
      is-centered
      @actionConfirmed="onConfirmApprove"
    >
      <template v-slot:body>
        <p class="font-weight-bold mb-10">
          {{ $t("page.payments.approveModalDescription") }}
        </p>

        <TextareaElement v-model="comment" :label="$t('label.comment')" class="mb-4" />
      </template>
    </ConfirmModal>

    <ConfirmModal
      ref="rejectModal"
      modal-id="rejectModal"
      :modal-title="$t('page.payments.rejectModalTitle')"
      is-centered
      :with-auto-close="false"
      :confirm-button-title="$t('button.reject')"
      @actionConfirmed="onConfirmReject"
    >
      <template v-slot:body>
        <p class="font-weight-bold mb-10">
          {{ $t("page.payments.rejectModalDescription") }}
        </p>

        <TextareaElement
          v-model="comment"
          :label="$t('label.comment')"
          class="mb-4"
          required
          :with-error="commentError"
          :error-message="$t('validation.required')"
          @blur="removeCommentError"
          @input="removeCommentError"
        />
      </template>
    </ConfirmModal>

    <PageModal ref="filtersModal" modal-id="filtersModal" :title="$t('label.filters')" is-centered>
      <OurFilters
        ref="ourFilters"
        :fields="fieldsForFilters"
        :applied-filters="appliedFilters"
        @applyFilters="onApplyFilters"
      />

      <template v-slot:footer>
        <div class="w-100 d-flex justify-content-between">
          <ButtonElement
            data-cy="find-with-filters"
            variant="primary"
            text="find"
            @click="onFindWithFilters"
          />

          <ButtonElement
            data-cy="reset-filters"
            variant="outline-primary"
            text="resetFilters"
            @click="onResetFilters"
          />
        </div>
      </template>
    </PageModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import apolloMixin from "@/mixins/apollo.mixin";
import { approveWithdrawal, rejectWithdrawal } from "../gql/mutations";
import { TYPE_VALUES } from "@/api/payments";
import withdrawalStatus from "@/configs/withdrawalStatus.json";

import KTCard from "@/components/card/KTCard";
import OurTable from "@/components/table/OurTable";
import SvgIcon from "@/components/svg/SvgIcon";
import ConfirmModal from "@/components/modal/ConfirmModal";
import TextareaElement from "@/components/form/TextareaElement";
import PageModal from "@/components/modal/PageModal";
import OurFilters from "@/components/OurFilters";
import ButtonElement from "@/components/ButtonElement";

const { withdrawals } = TYPE_VALUES;

export default {
  name: "EmployeeWithdrawalList",
  components: {
    KTCard,
    OurTable,
    SvgIcon,
    ConfirmModal,
    TextareaElement,
    PageModal,
    OurFilters,
    ButtonElement,
  },

  mixins: [apolloMixin],

  props: {
    paginationData: {
      type: Object,
      default: () => ({}),
    },

    withdrawals: {
      type: Array,
      default: () => [],
      required: true,
    },

    appliedFilters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      currentWithdrawalId: null,
      comment: "",
      commentError: false,
      fields: [
        {
          key: "dateCreate",
          label: this.$t("label.date"),
          type: "date",
        },
        {
          key: "status",
          label: this.$t("label.status"),
          type: "status",
          tdAttr: {
            style: "width: 25%; max-width: 135px;",
          },
          filter: {
            component: "select",
            type: "status",
            operator: "=",
            classes: "w-50",
          },
        },
        {
          key: "amount",
          label: this.$t("label.amount"),
          thClass: "text-right",
          tdAttr: {
            style: "width: 15%; max-width: 110px; text-align: right;",
          },
          filter: {
            component: "input",
            type: "number",
            operator: "like",
            classes: "w-50",
          },
        },
        {
          key: "agent",
          label: this.$t("label.agent._"),
          type: "link",
          tdAttr: {
            style: "width: 25%; max-width: 120px;",
          },
          filter: {
            component: "select-agent",
            column: "user.id",
            operator: "=",
          },
        },
        {
          key: "comment",
          label: this.$t("label.comment"),
          tdAttr: {
            style: "width: 25%; max-width: 110px; white-space: normal;",
          },
          filter: {
            component: "input",
            type: "text",
            operator: "like",
          },
        },
        {
          key: "actions",
          label: "",
          tdAttr: {
            style: "width: 80px;",
          },
        },
      ],
    };
  },

  computed: {
    ...mapState("withdrawal", ["statuses"]),
    ...mapState("currency", ["currency"]),

    allWithdrawals() {
      return this.withdrawals.map((withdrawal) => ({
        id: withdrawal.id,
        dateCreate: withdrawal.createdAt,
        status: this.statuses.find((item) => item.code === withdrawal.status),
        amount: `${withdrawal.amount} ${this.currency}`,
        comment: withdrawal.comment || "–",
        agent: {
          title: `${withdrawal.user.firstName} ${withdrawal.user.lastName}`,
          route: {
            name: "AgentEdit",
            params: { id: withdrawal.user.id },
          },
        },
      }));
    },

    fieldsForFilters() {
      return this.fields.map((field) => {
        if (field.key === "status") {
          field.filter.selectOptions = this.statuses;
          field.filter.trackBy = "code";
        }

        return field;
      });
    },
  },

  methods: {
    setCurrentWithdrawalId({ id }) {
      this.currentWithdrawalId = id;
    },

    async onConfirmApprove() {
      const approvedWithdrawal = await this.$post(approveWithdrawal, {
        id: this.currentWithdrawalId,
        comment: this.comment,
      });

      if (approvedWithdrawal) {
        this.$emit("changeWithdrawalStatus");

        this.comment = "";
      }
    },

    async onConfirmReject() {
      if (!this.isValidForm()) return;

      const rejectedWithdrawal = await this.$post(rejectWithdrawal, {
        id: this.currentWithdrawalId,
        comment: this.comment,
      });

      if (rejectedWithdrawal) {
        this.$refs.rejectModal.closeModal();

        this.$emit("changeWithdrawalStatus");

        this.comment = "";
      }
    },

    isValidForm() {
      this.commentError = !this.comment.length;

      return !this.commentError;
    },

    isStatusNew({ status }) {
      return status.code === withdrawalStatus.new;
    },

    removeCommentError() {
      this.commentError = false;
    },

    onPageChange(page) {
      this.$emit("pageChange", page);
    },

    showFiltersModal() {
      this.$refs.filtersModal.showModal();
    },

    onFindWithFilters() {
      this.$refs.ourFilters.applyFilters();
      this.$refs.filtersModal.closeModal();
    },

    onApplyFilters(filters) {
      this.$emit("applyFilters", {
        type: withdrawals,
        fields: this.fields,
        filters,
      });
    },

    onResetFilters() {
      this.$emit("resetFilters", withdrawals);

      this.$refs.filtersModal.closeModal();
    },
  },
};
</script>
