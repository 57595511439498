<template>
  <div data-cy="transaction-form">
    <b-row class="mb-7">
      <b-col cols="6">
        <MultiselectElement
          :value="checkValueExists(form.type)"
          :options="types"
          :label="$t('label.type')"
          required
          :with-error="errors.type"
          :error-message="$t('validation.required')"
          track-by="type"
          @select="onSelectType"
        />
      </b-col>
      <b-col cols="6">
        <InputElement
          v-model.number="form.amount"
          required
          :with-error="errors.amount"
          :error-message="$t('validation.required')"
          input-type="number"
          :label="$t('label.amount')"
        />
      </b-col>
    </b-row>
    <b-row class="mb-7">
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.referral)"
          :options="referralsList"
          :label="$t('label.order._')"
          required
          :disabled="isReferralId"
          :with-error="errors.referral"
          :error-message="$t('validation.required')"
          @select="onSelectReferral"
          @searchChange="searchChange"
        />
      </b-col>
    </b-row>
    <b-row class="mb-7">
      <b-col cols="12">
        <MultiselectElement
          :value="checkValueExists(form.agent)"
          :options="agents"
          :label="$t('label.agent._')"
          required
          :with-error="errors.agent"
          :error-message="$t('validation.required')"
          @select="onSelectAgent"
        />
      </b-col>
    </b-row>
    <b-row class="mb-7">
      <b-col>
        <TextareaElement v-model="form.comment" :label="$t('label.comment')" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputElement from "@/components/form/InputElement";
import MultiselectElement from "@/components/form/MultiselectElement";
import TextareaElement from "@/components/form/TextareaElement";
import { referralAgentsList } from "../_gql/queries";
import apolloMixin from "@/mixins/apollo.mixin";

export default {
  name: "TransactionForm",
  components: {
    InputElement,
    MultiselectElement,
    TextareaElement,
  },

  mixins: [apolloMixin],

  props: {
    referrals: {
      type: Array,
      default: () => [],
    },

    referralId: {
      type: String,
      default: "",
    },

    transaction: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    form: {
      type: "",
      amount: "",
      referral: "",
      comment: "",
      agent: "",
    },
    agents: [],
    errors: {
      type: false,
      amount: false,
      referral: false,
      agent: false,
    },
  }),

  computed: {
    types() {
      return [
        { type: "accrual", label: this.$t("label.operation.types.accrual") },
        {
          type: "accrualCancel",
          label: this.$t("label.operation.types.accrualCancel"),
        },
      ];
    },

    referralsList() {
      return this.referrals.map((referral) => {
        const { id, name: label } = referral;

        return { id, label };
      });
    },

    isReferralId() {
      return Boolean(this.referralId);
    },
  },

  watch: {
    referralsList: {
      handler: "onChangeReferralsList",
      deep: true,
    },

    transaction: {
      handler: "onChangeTransaction",
      immediate: true,
      deep: true,
    },
  },

  methods: {
    checkValueExists(value) {
      return value ? [value] : [];
    },

    onChangeReferralsList() {
      if (this.referralId) {
        this.form.referral = this.referralsList.find(
          (referral) => referral.id === this.referralId
        );
      }
    },

    onChangeTransaction() {
      if (this.transaction) {
        const { id, amount, comment, type, referral, user } = this.transaction;
        const transactionType = this.types.find((item) => item.type === type);
        const agentName = user
          ? `${user?.firstName} ${user?.lastName}`
          : `${referral.user?.firstName} ${referral.user?.lastName}`;

        const agent = {
          id: user ? user.id : referral.user.id,
          label: agentName,
        };

        this.form = {
          id,
          amount,
          comment,
          type: transactionType,
          referral: {
            id: referral?.id,
            label: referral?.name,
          },
          agent,
        };
      }
    },

    onSelectType(type) {
      this.form.type = type;
    },

    onSelectReferral(referral) {
      this.form.referral = referral;

      this.fetchRefAgents(referral.id);
    },

    onSelectAgent(agent) {
      this.form.agent = agent;
    },

    async fetchRefAgents(id) {
      const { referralAgents } = await this.$get(referralAgentsList, { id });

      this.agents = referralAgents.agents.map((row) => ({
        id: row.user.id,
        label: `${row.user.first_name} ${row.user.last_name}`,
      }));
    },

    searchChange(query) {
      this.$emit("searchChange", query);
    },

    isValidForm() {
      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.type = !this.form.type;
      this.errors.amount = !this.form.amount;
      this.errors.referral = !this.form.referral;
      this.errors.agent = !this.form.agent;

      return !Object.values(this.errors).some((error) => error);
    },

    submitForm() {
      if (!this.isValidForm()) return;

      const { id, type, amount, referral, comment, agent } = this.form;

      const transactionData = {
        id,
        type: type.type,
        amount,
        referral: { associate: referral.id },
        comment,
        userId: parseInt(agent.id),
      };

      this.$emit("submitForm", transactionData);
    },
  },
};
</script>

<style scoped lang="scss">
.form-group::v-deep {
  margin-bottom: 0;
}
</style>
