import gql from "graphql-tag";

export const approveWithdrawal = gql`
  mutation approveWithdrawal($id: ID!, $comment: String) {
    approveWithdrawal(id: $id, comment: $comment) {
      id
      amount
      comment
      status
      user {
        id
      }
    }
  }
`;

export const rejectWithdrawal = gql`
  mutation rejectWithdrawal($id: ID!, $comment: String!) {
    rejectWithdrawal(id: $id, comment: $comment) {
      id
      amount
      comment
      status
      user {
        id
      }
    }
  }
`;
